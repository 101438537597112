import React from 'react'
import * as Styled from './SectionImageText.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'

const SectionImageText = ({
  id,
  title,
  smallTitle,
  titleVariant,
  content,
  extraContent,
  image,
  bgText,
  page,
  imageFullWidth,
  reverseLarge,
  nordicLogistics,
}) => {
  return (
    <Styled.Section
      id={id}
      bgText={bgText}
      reverseLarge={reverseLarge}
      imageFullWidth={imageFullWidth}
      nordicLogistics={nordicLogistics}
    >
      <Row alignMiddle alignJustify={imageFullWidth}>
        <Column
          xs={12}
          lg={5}
          xl={imageFullWidth ? 5 : 6}
          largeOrder={reverseLarge ? 2 : 1}
          textCenter
          noPaddingMobile={imageFullWidth}
        >
          {imageFullWidth ? (
            <Styled.ImageWrapper>{image}</Styled.ImageWrapper>
          ) : (
            image
          )}
        </Column>
        <Column xs={12} lg={7} xl={6} largeOrder={reverseLarge ? 1 : 2}>
          <DoubleTitle
            smallTitle={smallTitle}
            title={title}
            variant={titleVariant || 'h3'}
            border="addon"
          />
          <p className="lead">{content}</p>
          {extraContent || null}
          {page && (
            <Button primary page={page}>
              Zobacz ofertę
            </Button>
          )}
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionImageText
