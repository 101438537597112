import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 100px 0 80px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 140px 0 120px;
  }

  &:before {
    content: '${props => props.bgText}';
    position: absolute;
    font-size: 400px;
    line-height: 0.75;
    color: transparent;
    font-weight: 600;
    top: 0;
    z-index: -1;
    -webkit-text-stroke: 3px ${props => props.theme.colors.light};

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      top: 50px;
      left: -80px;
    }
  }

  ${props =>
    props.reverseLarge === true &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        &:before {
          left: auto;
          right: -80px;
        }
      }
    `}

  ${props =>
    props.imageFullWidth === true &&
    css`
      &:before {
        @media (max-width: ${props.theme.breakpoints.lg}) {
          font-size: 200px;
          top: 65vw;
          left: 75%;
        }
        @media (min-width: ${props.theme.breakpoints.lgUp}) {
          z-index: 1;
        }
      }
    `}

  svg {
    height: 180px;
    width: auto;
    margin: 70px auto 60px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      height: 350px;
      margin: 20px auto;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      height: 520px;
    }
  }

  .lead {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-bottom: 32px;
    }
  }

  .lead + a {
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin: 24px 0 0;
    }
  }

  ${props =>
    props.nordicLogistics === true &&
    css`
      @media (max-width: ${props.theme.breakpoints.lg}) {
        padding-top: 320px;
      }

      .gatsby-image-wrapper {
        position: absolute;

        @media (max-width: ${props.theme.breakpoints.lg}) {
          height: 300px;
          width: 950px;
          top: 0;
          right: 0;
        }

        @media (min-width: ${props.theme.breakpoints.lgUp}) {
          height: 500px;
          width: 1600px;
          right: 55%;
          top: calc(50% - 250px);
        }
      }
    `}
`

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 60%;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 32px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    left: -${props => props.theme.grid.sideMargin};
    right: -${props => props.theme.grid.sideMargin};
    top: 0;
    bottom: 0;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      left: 0;
      right: 0;
    }
  }
`

export { Section, ImageWrapper }
