import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import SectionHeader from '../components/SectionHeader'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionImageText from '../components/SectionImageText'
import SectionBgImageText from '../components/SectionBgImageText'

export default function Home() {
  const {
    headerBgMobile,
    headerBg,
    mapBg,
    bydgoszczBgMobile,
    bydgoszczBg,
    sectionBgMobile,
    sectionBg,
  } = useStaticQuery(
    graphql`
      query {
        headerBgMobile: file(relativePath: { eq: "home/home-bg-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        headerBg: file(relativePath: { eq: "home/home-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        mapBg: file(relativePath: { eq: "o-nas/mapa-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        bydgoszczBgMobile: file(
          relativePath: { eq: "bydgoszcz/bydgoszcz-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        bydgoszczBg: file(relativePath: { eq: "bydgoszcz/bydgoszcz-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sectionBgMobile: file(
          relativePath: { eq: "bydgoszcz/przewoz-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sectionBg: file(relativePath: { eq: "bydgoszcz/przewoz-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  const headerBdgImages = [
    { ...getImage(bydgoszczBgMobile) },
    {
      ...getImage(bydgoszczBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  const sectionImages = [
    { ...getImage(sectionBgMobile) },
    {
      ...getImage(sectionBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="O nas"
      metaDescription="Wsparcie w sukcesie Twojego biznesu jest najważniejszym celem, któremu podporządkowujemy współpracę. "
      slug="/o-nas"
    >
      <SectionHeader
        smallTitle="O nas"
        title={
          <>
            Nordic <br />
            Logistics
          </>
        }
        titleBorder="white"
        lead="Wsparcie w sukcesie Twojego biznesu jest najważniejszym celem, któremu podporządkowujemy współpracę."
        content={
          <>
            Możesz na nas liczyć, jeśli poszukujesz doświadczonej firmy
            świadczącej kompleksowe usługi transportowe. Odpowiadamy za
            organizację przewozu Twoich ładunków, dodatkowo – na Twoje życzenie
            – zapewniamy Ci ubezpieczenie CARGO. Kompleksowy charakter naszej
            oferty polega na tym, że kierujemy ją do każdego Klienta
            i&nbsp;dostarczamy wszelkiego rodzaju towary, w dowolnych ilościach,
            w każde miejsce na mapie Europy i&nbsp;nie tylko.
          </>
        }
        scroll="#czytaj-wiecej"
        bgImage={headerImages}
        nordicLogistics
      />
      <BgImage image={getImage(mapBg)}>
        <SectionImageText
          id="czytaj-wiecej"
          smallTitle="O nas"
          title="Współpraca"
          titleVariant="h2"
          content={
            <>
              Zachęcamy do współpracy, zarówno eksporterów, jak
              i&nbsp;importerów, w&nbsp;tym przedsiębiorstwa prowadzące wymianę
              gospodarczą z&nbsp;państwami z&nbsp;Europy Zachodniej, które nie
              należą do UE, a&nbsp;także z&nbsp;byłymi republikami ZSRR, jak np.
              Kazachstan, Uzbekistan, Kirgistan, czy Tadżykistan.
            </>
          }
          extraContent={
            <p>
              Szeroki zakres usług transportowych jest możliwy dzięki doborowi
              sprawdzonych oraz rzetelnych podwykonawców. Rozbudowana sieć
              partnerów pozwala na zapewnienie efektywnego wsparcia
              w&nbsp;realizacji nawet najbardziej skomplikowanych zleceń.
            </p>
          }
          image={<StaticImage src="../images/o-nas/wspolpraca.jpg" alt="" />}
          imageFullWidth
        />
        <SectionImageText
          smallTitle="O nas"
          title={<>Pewny partner w&nbsp;transporcie</>}
          titleVariant="h2"
          content={
            <>
              Możesz na nas liczyć, jeśli interesuje Cię kompleksowa obsługa
              i&nbsp;kontrola procesów transportowych. Gwarancja szybkiego
              kontaktu z&nbsp;dedykowanym opiekunem i&nbsp;pełna dyspozycyjność
              na każdym etapie współpracy składają się na satysfakcjonujące
              wsparcie, którego oczekujesz.
            </>
          }
          extraContent={
            <>
              <p>
                Elastyczne i indywidualne podejście do wszystkich zleceń
                przekłada się na terminowość i bezpieczeństwo dostaw, czyli
                kluczową kwestię w przypadku transportu towarów.
              </p>
              <p>
                Wychodzimy naprzeciw Twoim oczekiwaniom również pod względem
                finansowym, na co pozwala dobór rozwiązań ograniczających koszty
                do minimum. Przejrzyste warunki, mało formalności
                i&nbsp;korzystne stawki umożliwiają nam pozyskiwanie zaufania
                Klientów z całej Europy i&nbsp;najróżniejszych branż.
                Doświadczony zespół spedytorów tworzących naszą firmę oferuje
                wsparcie w&nbsp;czynnościach celnych i&nbsp;pomoc przy zakupie
                ubezpieczeń CARGO. W&nbsp;razie potrzeby realizujemy też
                transporty kabotażowe. Zapraszamy do kontaktu i&nbsp;współpracy!
              </p>
            </>
          }
          image={
            <StaticImage
              src="../images/o-nas/pewny-partner-w-transporcie.jpg"
              alt=""
            />
          }
          imageFullWidth
          reverseLarge
        />
      </BgImage>
      <SectionHeader
        smallTitle="O nas"
        title="Transport"
        titleComponent="h2"
        titleBorder="white"
        lead="Jeśli poszukujesz wsparcia w&nbsp;logistyce i&nbsp;przewozie towarów, zapraszamy do współpracy."
        content={
          <>
            Obsługujemy Klientów nie tylko z&nbsp;Bydgoszczy, ale z&nbsp;całej
            Polski, a&nbsp;także zagranicznych przedsiębiorców, którzy chcą
            powierzyć specjalistom ciężarowy transport drogowy dowolnego
            rodzaju. Z&nbsp;powodzeniem realizujemy zlecenia na ładunki
            neutralne, całopojazdowe, ponadgabarytowe, drobnicowe i&nbsp;inne.
            Możesz przekazać nam wszelkiego rodzaju towary w&nbsp;każdych
            ilościach – rozbudowana sieć partnerów w całej Europie pozwala nam
            na wykonywanie drogowego transportu i&nbsp;zorganizowanie logistyki
            na najwyższym poziomie.
          </>
        }
        bgImage={headerBdgImages}
        contentCentered
      />
      <SectionImageText
        smallTitle="O nas"
        title={<>Transport ciężarowy wszelkiego rodzaju</>}
        content={
          <>
            Dobór sprawdzonych oraz rzetelnych Podwykonawców to kluczowy aspekt
            naszej działalności, dlatego przykładamy do niego szczególną wagę.
          </>
        }
        extraContent={
          <p>
            Współpracujemy tylko z wysoko wykwalifikowaną kadrą, przed którą
            logistyka nie ma żadnych tajemnic. Doskonale znamy się na swoim
            fachu, dzięki czemu możemy zapewnić usługi na najwyższym poziomie.
            Dysponujemy zasobami, które umożliwiają realizowanie nawet
            najbardziej skomplikowanych i&nbsp;wymagających zleceń, wliczając
            w&nbsp;to przewóz towarów ponadgabarytowych, takich jak konstrukcje
            stalowe, maszyny i&nbsp;sprzęty budowlane, przemysłowe, czy rolnicze
            lub inne przedmioty, obiekty oraz pojazdy o&nbsp;bardzo dużych
            rozmiarach, ogromnej masie lub nietypowych kształtach.
          </p>
        }
        image={
          <StaticImage
            src="../images/bydgoszcz/transport-ciezarowy.jpg"
            alt=""
            height={500}
          />
        }
        nordicLogistics
      />
      <SectionBgImageText
        smallTitle="O nas"
        title="Szybki, bezpieczny i&nbsp;konkurencyjny cenowo przewóz towarów"
        lead={
          <>
            Zapraszamy do współpracy Klientów z&nbsp;całej Polski, niezależnie
            od ilości i&nbsp;charakterystyki zleconego transportu.
          </>
        }
        content={
          <>
            <div className="two-columns">
              <p>
                Z powodzeniem realizujemy, zarówno niewielkie zlecenia
                o&nbsp;ograniczonym zasięgu i&nbsp;przy małej zajętości
                przestrzeni pojazdu, jak i&nbsp;rozbudowane, zaawansowane
                przewozy, obejmujące kilka pełnych ciężarówek. Elastyczne
                i&nbsp;indywidualne podejście do każdego zlecenia jest jednym
                z&nbsp;filarów oferty, na których bazuje nasza konkurencyjność.
                Również gwarancja szybkiego kontaktu z&nbsp;dedykowanym
                opiekunem przekonuje Klientów do współpracy.
              </p>
              <p>
                Mamy świadomość, że ważne są terminowość i&nbsp;bezpieczeństwo
                dostaw, dlatego korzystamy z&nbsp;rozwiązań, które pozwalają na
                monitorowanie ruchu pojazdów na bieżąco i&nbsp;obliczanie
                szacowanego terminu dotarcia do celu. Logistyka to nasza
                specjalność, a&nbsp;ponieważ tworzymy doświadczony zespół
                spedytorów, wysokie kompetencje i&nbsp;fachowe umiejętności idą
                w&nbsp;parze z&nbsp;pasją, z&nbsp;jaką z&nbsp;naszej perspektywy
                wiąże się obsługa procesu spedycji.
              </p>
            </div>
            <p>
              Skontaktuj się z nami niezależnie od tego, czy przedmiotem
              zlecenia ma być logistyka ogromnych ładunków, czy szybki przewóz
              na przykład chłodniczy niewielkich towarów.
            </p>
          </>
        }
        bgImage={sectionImages}
      />
    </Layout>
  )
}
